import React, { FC } from 'react'
import { Row, Col, Typography } from 'antd'
import { graphql, PageProps } from 'gatsby'

import Layout from '@components/Layout'
import SEO from '@components/SEO'
import StyledContainer from '@root/ic-ui-react/StyledContainer'
import Hero from '@root/components/Hero'

export const pageQuery = graphql`
  query {
    GreekLandscape: file(relativePath: { eq: "greeklandscape.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4542) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const { Title, Paragraph } = Typography

const About: FC<PageProps> = (props) => (
  <Layout>
    <SEO
      title="About Us"
      description="With over 40 years’ experience in the fast food restaurant industry, it was time for us to go back to our roots- Authentic Greek Food"
    />

    <StyledContainer fluid>
      <Hero
        title="With over 40 years’ experience in the fast food restaurant industry, it was time for us to go back to our roots- Authentic Greek Food"
        imgSrc={props.data.GreekLandscape.childImageSharp.fluid.src}
        xPlacement="center"
        yPlacement="center"
      />
    </StyledContainer>

    <StyledContainer padding="1rem">
      <Row gutter={10}>
        <Col span={24}>
          <Paragraph>
            Growing up in Athens, my mother Penelope grew up in a household that was influenced by
            the recipes that her grandmother brought from Smyrna. These aromas filled the kitchen
            and made Grandma Penelope’s home very popular in the neighborhood for a “meze” and some
            good conversation. My grandmother, Christina, continued these recipes and taught her
            daughter, my mother, how to add a few touches to make Sunday dinner more than a
            tradition but something that to this day brings the family together- just because!{' '}
            <br /> <br /> That is our mission with Pita Pitaki- to bring authentic homemade Greek
            food to you. To bring the aromas, fresh ingredients, and some conversation to your
            dining experience. (Us Greeks like to talk!) We are family owned and operated; adding an
            even more home-style atmosphere to your dining experience. We hope you enjoy your meal,
            and make Pita Pitaki your choice for authentic Greek food in Long Beach.
          </Paragraph>

          <Title level={4}>Penelope and Christina Marangos, Owners</Title>
        </Col>
      </Row>
    </StyledContainer>
  </Layout>
)

export default About
