import { FC } from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'

interface HeroProps {
  xPlacement?: 'left' | 'right' | 'center'
  yPlacement?: 'start' | 'end' | 'center'
  imgSrc: string
}

const HeroContainer = styled.div<HeroProps>`
  position: relative;
  width: 100%;
  height: 58vh;
  display: flex;
  align-items: ${(props) => props.yPlacement || 'center'};
  justify-content: ${(props) => props.xPlacement || 'center'};

  &::before {
    content: '';
    background-image: url(${(props) => props.imgSrc || 'https://placekitten.com/1200/800'});
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.8;
  }
`

const Hero: FC<{
  title: string
  subtitle?: string
  imgSrc: string
  xPlacement?: 'left' | 'right' | 'center'
  yPlacement?: 'start' | 'end' | 'center'
}> = ({ title, subtitle, imgSrc, xPlacement, yPlacement }) => (
  <HeroContainer imgSrc={imgSrc} xPlacement={xPlacement} yPlacement={yPlacement}>
    <Typography.Title
      level={4}
      style={{
        padding: '1.5rem',
        textAlign: 'center',
        color: 'white',
        zIndex: 1,
      }}
    >
      {title}
    </Typography.Title>
    {subtitle && <Typography.Text type="secondary">{subtitle}</Typography.Text>}
  </HeroContainer>
)

export default Hero
